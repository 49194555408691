//TODO replace with ref data from API
export const creditStatus = [
  { key: 'A', text: 'Authorisation required' },
  { key: 'B', text: 'Bad - No new licences' },
  { key: 'G', text: 'Good - New licences OK' },
];

const restrictedTypes = [
  { key: 'BUS', text: 'Business' },
  { key: 'OTH', text: 'Others' },
  { key: 'OCY', text: 'Overseas Company' },
];

//TODO replace with ref data from API
export const clientTypes = (includeRestrictedTypes: boolean, excludePerson?: boolean, includeLegacyTypes?: boolean) => {
  const clientTypes = [
    { key: 'ARC', text: 'Amateur Radio Club' },
    { key: 'CHT', text: 'Charitable Trust' },
    { key: 'COL', text: 'Council' },
    { key: 'GMD', text: 'Government Dept' },
    { key: 'IST', text: 'Incorporated Society' },
    { key: 'LPP', text: 'Limited Partnership' },
    { key: 'PAR', text: 'Partnership' },
    { key: 'REC', text: 'Registered Company' },
    { key: 'SBY', text: 'Statutory Body' },
  ].sort((a, b) => a.text.localeCompare(b.text));

  const filteredRestrictedTypes = includeLegacyTypes
    ? restrictedTypes
    : [
        { key: 'OTH', text: 'Others' },
        { key: 'OCY', text: 'Overseas Company' },
      ];

  const withRetrictedTypes = [...clientTypes, ...filteredRestrictedTypes].sort((a, b) => a.text.localeCompare(b.text));

  const organisationTypes = includeRestrictedTypes ? withRetrictedTypes : clientTypes;

  return excludePerson ? organisationTypes : [{ key: 'PER', text: 'Person' }, ...organisationTypes];
};

export const isRestrictedType = (clientType: string) => restrictedTypes.find((type) => type.key === clientType) !== undefined;

export const getPreviousMonth = (monthIndex: number) => {
  return monthIndex === 0 ? 'DEC' : months[monthIndex - 1].key;
};

export const reasonTypes = [
  { key: 'Licence to transmit radio waves', text: 'Licence to transmit radio waves' },
  { key: 'Licence to supply radio transmitters', text: 'Licence to supply radio transmitters' },
  {
    key: 'Radio operators Certificate of Competency to operate amateur or maritime radio equipment',
    text: 'Radio operators Certificate of Competency to operate amateur or maritime radio equipment',
  },
  { key: 'Amateur or maritime radio callsign', text: 'Amateur or maritime radio callsign' },
  { key: 'Lodge a mortgage or caveat', text: 'Lodge a mortgage or caveat' },
  { key: 'Use API', text: 'Use API' },
  { key: 'Other', text: 'Other' },
];

// Reference data?
export const months = [
  { key: 'JAN', text: 'January' },
  { key: 'FEB', text: 'February' },
  { key: 'MAR', text: 'March' },
  { key: 'APR', text: 'April' },
  { key: 'MAY', text: 'May' },
  { key: 'JUN', text: 'June' },
  { key: 'JUL', text: 'July' },
  { key: 'AUG', text: 'August' },
  { key: 'SEP', text: 'September' },
  { key: 'OCT', text: 'October' },
  { key: 'NOV', text: 'November' },
  { key: 'DEC', text: 'December' },
];

export const titles = [
  { key: 'MR', text: 'Mr' },
  { key: 'MRS', text: 'Mrs' },
  { key: 'MISS', text: 'Miss' },
  { key: 'MS', text: 'Ms' },
  { key: 'DR', text: 'Doctor' },
  { key: 'SIR', text: 'Sir' },
  { key: 'DAME', text: 'Dame' },
  { key: 'CAPTN', text: 'Captain' },
  { key: 'COMMDR', text: 'Commodore' },
];

export const nzbnRequired = (clientType: any) =>
  clientType ? clientType === 'REC' || clientType === 'LPP' || clientType === 'CHT' || clientType === 'IST' : false;

export const contactTypes = [
  { key: 'Legal', text: 'Legal' },
  { key: 'Licence', text: 'Licence' },
  { key: 'Technical', text: 'Technical' },
  { key: 'Payment', text: 'Payment' },
];
