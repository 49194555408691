import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { useAuth } from 'common/auth/AuthHook';

import ProtectedRoute from '../../common/auth/ProtectedRoute';

const FormTenPage = React.lazy(() => import('licence_management/cancel_spectrum_licence_form_ten/FormTenPage'));
const FormSevenPage = React.lazy(() => import('licence_management/create_spectrum_form_seven/FormSevenPage'));
const FormFivePage = React.lazy(() => import('licence_management/management_right/modify/form_five/FormFivePage'));
const FormEightPage = React.lazy(() => import('licence_management/modify_spectrum_licence_form_eight/FormEightPage'));
const Form11Page = React.lazy(() => import('licence_management/mortgage_caveat/form_11/Form11Page'));
const Form18Page = React.lazy(() => import('licence_management/mortgage_caveat/form_18/Form18Page'));
const Form20Page = React.lazy(() => import('licence_management/mortgage_caveat/form_20/Form20Page'));
const Form21Page = React.lazy(() => import('licence_management/mortgage_caveat/form_21/Form21Page'));
const LegacyFormTenPage = React.lazy(() => import('../cancel_spectrum_licence_form_ten/LegacyFormTenPage'));
const LegacyFormSevenPage = React.lazy(() => import('../create_spectrum_form_seven/LegacyFormSevenPage'));
const FormNinePage = React.lazy(() => import('../transfer_spectrum_licence_form_nine/FormNinePage'));
const LegacyFormNinePage = React.lazy(() => import('../transfer_spectrum_licence_form_nine/LegacyFormNinePage'));
const ManagementRightApplicationPage = React.lazy(() => import('./apply/ManagementRightApplicationPage'));
const FormFourPage = React.lazy(() => import('./modify/form_four/FormFourPage'));
const FormSixPage = React.lazy(() => import('./modify/form_six/FormSixPage'));
const FormTwoPage = React.lazy(() => import('./modify/form_two/FormTwoPage'));
const ManagementRightSummaryPage = React.lazy(() => import('./summary/ManagementRightSummaryPage'));

export const MANAGEMENT_RIGHT_ROOT_URL = '/ui/app/licence/management-rights';
export const MANAGEMENT_RIGHT_PUBLIC_ROOT_URL = '/ui/management-right/';
export const VIEW_MANAGEMENT_RIGHT_URL = '/view/';
export const APPLY_MANAGEMENT_RIGHT_URL = '/apply';

export const useManagementRightNavigator = () => {
  const history = useHistory();
  const { loggedIn } = useAuth();

  return {
    navigateToViewManagementRight: (id: number, newTab?: boolean) => {
      const url = loggedIn ? MANAGEMENT_RIGHT_ROOT_URL + VIEW_MANAGEMENT_RIGHT_URL + id : MANAGEMENT_RIGHT_PUBLIC_ROOT_URL + id;
      newTab ? window.open(url, '_blank') : history.push(url);
    },
    navigateToApplyManagementRight: () => history.push(MANAGEMENT_RIGHT_ROOT_URL + APPLY_MANAGEMENT_RIGHT_URL),
    navigateToCraftManagementRight: (applicationId: number, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/craft/${applicationId}`)
        : history.push(`/ui/app/licence/management-rights/craft/${applicationId}`),
    navigateToFormTwo: (applicationId?: number, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/form-two${applicationId ? '/' + applicationId : ''}`)
        : history.push(`/ui/app/licence/management-rights/form-two${applicationId ? '/' + applicationId : ''}`),
    navigateToFormFour: (formId?: number, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/form-four${formId ? '/' + formId : ''}`)
        : history.push(`/ui/app/licence/management-rights/form-four${formId ? '/' + formId : ''}`),
    navigateToFormFive: (formId?: number, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/form-five${formId ? '/' + formId : ''}`)
        : history.push(`/ui/app/licence/management-rights/form-five${formId ? '/' + formId : ''}`),
    navigateToFormSix: (formId?: number, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/form-six${formId ? '/' + formId : ''}`)
        : history.push(`/ui/app/licence/management-rights/form-six${formId ? '/' + formId : ''}`),
    navigateToFormSeven: (formId?: string, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/form-seven${formId ? '/' + formId : ''}`)
        : history.push(`/ui/app/licence/management-rights/form-seven${formId ? '/' + formId : ''}`),
    navigateToFormEight: (formId?: string, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/form-eight${formId ? '/' + formId : ''}`)
        : history.push(`/ui/app/licence/management-rights/form-eight${formId ? '/' + formId : ''}`),
    navigateToFormNine: (formId?: string, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/form-nine${formId ? '/' + formId : ''}`)
        : history.push(`/ui/app/licence/management-rights/form-nine${formId ? '/' + formId : ''}`),
    navigateToFormTen: (formId?: string, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/licence/management-rights/form-ten${formId ? '/' + formId : ''}`)
        : history.push(`/ui/app/licence/management-rights/form-ten${formId ? '/' + formId : ''}`),
  };
};

const ManagementRightRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${path}${VIEW_MANAGEMENT_RIGHT_URL}:id`} component={ManagementRightSummaryPage} />
      <ProtectedRoute path={`${path}/apply`} component={ManagementRightApplicationPage} permission={['CREATE_MANAGEMENT_RIGHT_APPLICATION']} />
      <ProtectedRoute path={`${path}/craft/:applicationId`} component={ManagementRightApplicationPage} />
      <ProtectedRoute path={`${path}/form-two/:formId/:consentId`} component={FormTwoPage} />
      <ProtectedRoute path={`${path}/form-two/:formId`} component={FormTwoPage} />
      <ProtectedRoute path={`${path}/form-two`} component={FormTwoPage} permission={['VIEW_MODIFY_MANAGEMENT_RIGHT']} />
      <ProtectedRoute path={`${path}/form-four/:formId`} component={FormFourPage} />
      <ProtectedRoute path={`${path}/form-four`} component={FormFourPage} permission={['VIEW_MODIFY_MANAGEMENT_RIGHT']} />
      <ProtectedRoute path={`${path}/form-five/:formId`} component={FormFivePage} />
      <ProtectedRoute path={`${path}/form-five`} component={FormFivePage} permission={['VIEW_MODIFY_MANAGEMENT_RIGHT']} />
      <ProtectedRoute path={`${path}/form-six/:formId/:reviewMrId`} component={FormSixPage} />
      <ProtectedRoute path={`${path}/form-six/:formId`} component={FormSixPage} />
      <ProtectedRoute path={`${path}/form-six`} component={FormSixPage} permission={['VIEW_MODIFY_MANAGEMENT_RIGHT']} />
      <ProtectedRoute path={`${path}/form-seven/legacy/:formId`} component={LegacyFormSevenPage} />
      <ProtectedRoute path={`${path}/form-nine/legacy/:formId`} component={LegacyFormNinePage} />
      <ProtectedRoute path={`${path}/form-ten/legacy/:formId`} component={LegacyFormTenPage} />
      <ProtectedRoute path={`${path}/form-seven/:formId/:consentId`} component={FormSevenPage} />
      <ProtectedRoute path={`${path}/form-seven/:formId`} component={FormSevenPage} />
      <ProtectedRoute path={`${path}/form-seven`} component={FormSevenPage} />
      <ProtectedRoute path={`${path}/form-eight/:formId/:consentId`} component={FormEightPage} />
      <ProtectedRoute path={`${path}/form-eight/:formId`} component={FormEightPage} />
      <ProtectedRoute path={`${path}/form-eight`} component={FormEightPage} />
      <ProtectedRoute path={`${path}/form-ten/:formId`} component={FormTenPage} />
      <ProtectedRoute path={`${path}/form-ten`} component={FormTenPage} />
      <ProtectedRoute path={`${path}/form-nine/:formId/:consentId`} component={FormNinePage} />
      <ProtectedRoute path={`${path}/form-nine/:formId`} component={FormNinePage} />
      <ProtectedRoute path={`${path}/form-nine`} component={FormNinePage} />

      <ProtectedRoute path={`${path}/form-11/:formId/:consentId`} component={Form11Page} />
      <ProtectedRoute path={`${path}/form-11/:formId`} component={Form11Page} />
      <ProtectedRoute path={`${path}/form-18/:formId`} component={Form18Page} />
      <ProtectedRoute path={`${path}/form-20/:formId`} component={Form20Page} />
      <ProtectedRoute path={`${path}/form-21/:formId`} component={Form21Page} />
    </Switch>
  );
};

export default ManagementRightRouter;
