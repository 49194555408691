import _ from 'lodash';

export const placeholderIfEmpty = (val: string | number | undefined, placeholder: string = '-') => {
  if (val === undefined || val === null || val === '') {
    return placeholder;
  }

  return val;
};

export const emptyStringIfUndefined = (val: string | number | undefined, emptyStringPlaceholder?: string): string => (val ? val.toString() : '');

export const numberToLetter = (i: number) => {
  const previousLetters: string = i >= 26 ? numberToLetter(Math.floor(i / 26) - 1) : '';
  const lastLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26];
  return previousLetters + lastLetter;
};

export const toSentenceCase = (val?: string) => _.upperFirst(_.lowerCase(val || ''));
