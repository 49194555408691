import { IDropdownOption, Label, MessageBarType } from '@fluentui/react';
import { Formik, FormikHelpers, useFormikContext, yupToFormErrors } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  BasicSearchResult,
  EngineerDto,
  GeoreferenceDto,
  LicenceDto,
  LicenceDtoLicenceTypeCodeEnum,
  LicenceTypeDto,
  ServiceTypeDto,
  ValidationCallsignResponse,
} from 'api-client';
import { SatelliteDtoSatelliteTypeEnum } from 'api-client/dist/api';

import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import DatePicker from 'common/controls/inputs/DatePicker';
import Dropdown from 'common/controls/inputs/Dropdown';
import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import ServiceLevelReadOnly from 'common/controls/inputs/ServiceLevelReadOnly';
import ServiceLevelSelector from 'common/controls/inputs/ServiceLevelSelector';
import TagPicker from 'common/controls/inputs/TagPicker';
import { TextField } from 'common/controls/inputs/TextField';
import ReadonlyData, { prettyMonth } from 'common/controls/items/ReadonlyData';
import TooltipHint from 'common/controls/items/TooltipHint';
import MoreOrLess from 'common/controls/surfaces/MoreOrLess';
import Form from 'common/form/Form';
import Accordion from 'common/layout/Accordion';
import usePage from 'common/layout/PageHook';

import { months } from '../../../../client_management/client/utils';
import ConfirmDialog from '../../../../common/controls/surfaces/ConfirmDialog';
import { formatDateFromISO } from '../../../../common/utils/dateUtils';
import { asOptions } from '../../../../common/utils/dropdownUtils';
import { formatMonetaryValue } from '../../../../common/utils/monetaryUtils';
import { validateWithFormValuesAsync } from '../../../../common/validation/validationWithFormValues';
import { isNotEmpty } from '../../../../common/validation/yupUtils';
import { usePublicApi } from '../../../../public/PublicApiHook';
import Grid from '../../../../ui-library/grid';
import { useLicenceApi } from '../../../LicenceApiHooks';
import { ApplyLicencePageContext } from '../../../apply/ApplyLicencePage';
import ManagementRightSection from '../../../apply/components/MangementRightSection';
import { asLatLongs, formatLatLongs, satelliteLicenceClassificationOptions } from '../../../apply/components/satellite/SatelliteDetailsSection';
import { serviceTypeToKeyValue } from '../../../apply/utils';
import { Application } from '../../../common/components/ApplicationDetails';
import { canBeReservedLicence, canHaveSystemId, findLicenceType, isSatelliteType } from '../../../common/utils';
import { useReferenceDataHookApi } from '../../../reference_data/ReferenceDataApiHook';
import { Licence } from '../../CraftLicencePage';
import AssignEngineerButton from './AssignEngineerButton';
import { basicDetailsSchema } from './basicDetailsValidationSchema';
import { basicDetailsSchemaFull } from './basicDetailsValidationSchemaFull';
import {
  availableLicenceClassificationsByLicenceType,
  defaultLicenceClassification,
  engineerName,
  radioLicenceClassificationOptions,
  spectrumLicenceClassificationOptions,
} from './utils';

const SPL = LicenceDtoLicenceTypeCodeEnum.SPL;

interface Props {
  licence?: Licence;
  application?: Application;
  serviceTypes: ServiceTypeDto[];
  serviceTypesForClassification: ServiceTypeDto[];
  level1: IDropdownOption[];
  engineer?: EngineerDto;
  onEngineerUpdated: (engineer?: EngineerDto) => void;
  sectionExpanded?: boolean;
  onDone: (isBasicDetailsDone: boolean) => void;
  onSubmit: (formValues: any, formikHelpers: FormikHelpers<any>) => Promise<void>;
  modifyLicence?: boolean;
  baseCallSignCapture?: boolean;
  mobileCallSignCapture?: boolean;
}

export const licenceAsFormValues = (licence?: Licence, serviceTypes?: ServiceTypeDto[]) => ({
  ...licence,
  classification: licence?.classification ?? defaultLicenceClassification(licence ?? {}, serviceTypes ?? []),
  fixedTerm: !licence?.renewable || licence?.licenceTypeCode === SPL,
  level1: licence?.selectedServiceLevels?.serviceLevel1?.description,
  level2: licence?.selectedServiceLevels?.serviceLevel2?.description,
  level3: licence?.selectedServiceLevels?.serviceLevel3?.description,
  licenceTypeId: licence?.selectedServiceLevels?.serviceLevel4?.id,
  licenceTypeName: licence?.selectedServiceLevels?.serviceLevel4?.description,
  licenceTypeDisplayCode: licence?.selectedServiceLevels?.serviceLevel4?.code,
});

interface AccordionContextInterface {
  expanded?: boolean;
  setExpanded: (expanded: boolean) => void;
}

const AccordionContext = React.createContext({} as AccordionContextInterface);

export const LicenceBasicDetails: React.FC<Props> = (props) => {
  const [expanded, setExpanded] = React.useState<boolean>();
  const requiresClassification = !!availableLicenceClassificationsByLicenceType(props.licence ?? {}, props.serviceTypes).length;
  const { callsignType, setTotalRequired } = findLicenceType(props.licence ?? {}, props.serviceTypes) ?? {};
  const { validateLicenceCallsign } = useLicenceApi();
  const existingCallsigns = [...(props.licence?.baseCallsigns ?? []), ...(props.licence?.mobileCallsigns ?? [])];
  const isBasicDetailsDone = props.licence ? props.licence.craftingProgress?.isBasicDetailsDone ?? false : undefined;
  let collapseAfterSubmitting = false;

  useEffect(() => {
    if (expanded === undefined && isBasicDetailsDone !== undefined) {
      setExpanded(!isBasicDetailsDone);
    }
  }, [isBasicDetailsDone]);

  const validateCallsign = (callsign: string): Promise<ValidationCallsignResponse> =>
    callsignType && !existingCallsigns.find((existing) => existing === callsign)
      ? validateLicenceCallsign({ callsign, callsignType })
      : Promise.resolve({ success: true });

  return (
    <AccordionContext.Provider value={{ expanded, setExpanded }}>
      <Formik
        initialValues={{
          ...licenceAsFormValues(props.licence, props.serviceTypesForClassification),
          baseCallsignRequired: props?.baseCallSignCapture,
          mobileCallsignRequired: props?.mobileCallSignCapture,
        }}
        onSubmit={(formValues, formikHelpers) =>
          props.onSubmit(formValues, formikHelpers).then(() => {
            if (collapseAfterSubmitting) {
              setExpanded(false);
              collapseAfterSubmitting = false;
            }
          })
        }
        validate={validateWithFormValuesAsync(basicDetailsSchema, { validateCallsign })}
        enableReinitialize
        validateOnChange={false} //this helps keeping errors displayed when Done pressed when accordion is collapsed
      >
        <LicenceBasicDetailsAccordion
          expanded={expanded}
          done={props.licence?.craftingProgress?.isBasicDetailsDone}
          onDone={(isDone) => {
            collapseAfterSubmitting = isDone;
            props.onDone(isDone);
            if (!isDone) {
              setExpanded(true);
            }
          }}
          requiresClassification={requiresClassification}
          modifyLicence={props.modifyLicence}
          validateCallsign={validateCallsign}
          setTotalRequired={setTotalRequired}
        >
          <LicenceBasicDetailsForm {...props} />
        </LicenceBasicDetailsAccordion>
      </Formik>
    </AccordionContext.Provider>
  );
};

const LicenceBasicDetailsAccordion: React.FC<{
  expanded?: boolean;
  done?: boolean;
  onDone: (isDone: boolean) => void;
  requiresClassification: boolean;
  modifyLicence?: boolean;
  validateCallsign: (callsign: string) => Promise<ValidationCallsignResponse>;
  setTotalRequired?: boolean;
  children?: React.ReactNode;
}> = (props) => {
  const { submitForm, values, setFieldTouched, setFieldError, setFieldValue } = useFormikContext();
  const [error, setError] = React.useState<boolean>(false);

  const handleDone = () => {
    if (props.done) {
      props.onDone(false);
    } else {
      props.onDone(true);
      basicDetailsSchemaFull
        .validate(values, {
          context: {
            context: {
              requiresClassification: props.requiresClassification,
              modifyRadioLicence: props.modifyLicence,
              validateCallsign: props.validateCallsign,
              setTotalRequired: props.setTotalRequired,
            },
          },
          abortEarly: false,
        })
        .then(() => {
          setFieldValue('craftingProgress.isBasicDetailsDone', true);
          submitForm().then(() => setError(false));
        })
        .catch((reason) => {
          const formErrors = yupToFormErrors(reason);
          setError(true);
          for (const [key, value] of Object.entries(formErrors)) {
            setFieldError(key, value + '');
            setFieldTouched(key, true, false); // get the error messages displayed
          }
        });
    }
  };

  return (
    <AccordionContext.Consumer>
      {({ setExpanded, expanded }) => (
        <Accordion
          {...props}
          onDone={handleDone}
          id="basic-licence-details"
          title="Basic licence details"
          error={error}
          onExpandCollapsed={() => setExpanded(!expanded)}
        >
          <ErrorLabel name="basic-details-error" showIcon />
          {props.children}
        </Accordion>
      )}
    </AccordionContext.Consumer>
  );
};

const LicenceBasicDetailsForm: React.FC<Props> = (props) => {
  const context = useFormikContext<any>();
  const isSpectrumLicence = props.licence?.licenceTypeCode === SPL;
  const { baseCallsign, mobileCallsign, setTotalRequired } = findLicenceType(props.licence ?? {}, props.serviceTypes) ?? {};
  const licenceTypeId = props.licence?.licenceTypeId;
  const { hasRole } = useAuth();
  const isRreOrSa = hasRole('ROLE_RSM_RADIO_ENGINEER') || hasRole('ROLE_SYSTEM_ADMINISTRATOR');
  const availableLicenceClassifications = availableLicenceClassificationsByLicenceType(props.licence ?? {}, props.serviceTypes);
  const [showLicenceTypeEdit, setShowLicenceTypeEdit] = React.useState(false);
  const [showLicenceTypeApply, setShowLicenceTypeApply] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
  const { setPageInstruction } = usePage();
  const landMobileLicenceTypeId = 211;

  const level1 = asOptions(props.serviceTypes.map(serviceTypeToKeyValue), true, true);
  const level2 = asOptions(props.serviceTypes.map(serviceTypeToKeyValue), true, true);

  const level1Selected = props.licence?.selectedServiceLevels?.serviceLevel1;
  const level2Selected = props.licence?.selectedServiceLevels?.serviceLevel2;
  const level3Selected = props.licence?.selectedServiceLevels?.serviceLevel3;
  const level4Selected = props.licence?.selectedServiceLevels?.serviceLevel4;

  const selectedLevelsReadOnly = {
    serviceLevel1: level1Selected,
    serviceLevel2: level2Selected,
    serviceLevel3: level3Selected,
    serviceLevel4: level4Selected,
  };

  const licenceClassificationPerType = isSatelliteType(licenceTypeId)
    ? satelliteLicenceClassificationOptions
    : isSpectrumLicence
    ? spectrumLicenceClassificationOptions
    : radioLicenceClassificationOptions;

  const licenceClassificationOptions = licenceClassificationPerType.filter((o) => availableLicenceClassifications.find((lc) => lc === o.key));

  const licenceClassification = licenceClassificationPerType.filter(
    (o) => o.key === defaultLicenceClassification(props.licence ?? {}, props.serviceTypesForClassification),
  )[0]?.text;

  const isPrimaryAssociation = !props.licence?.associated?.find((asl) => !asl.primaryAssociation);

  const isSpectrumLicenceModify = props.modifyLicence && isSpectrumLicence;

  const [latLongs, setLatLongs] = useState<GeoreferenceDto[]>();
  const { getLocation } = useReferenceDataHookApi();

  const { setNextLevelToValidate } = React.useContext(ApplyLicencePageContext);
  const paidTill = props.licence?.paidTillMonth + ' ' + props.licence?.paidTillYear;

  useEffect(() => {
    if (props.application?.satellite?.locationId) {
      getLocation(props.application?.satellite?.locationId, { disableErrorMessage: true }).then(asLatLongs).then(setLatLongs);
    }
  }, [props.application?.satellite?.locationId]);

  const handleLicenceTypeChange = () => {
    setShowLicenceTypeEdit(true);
    setShowLicenceTypeApply(false);
  };

  const handleLevel4Change = (key: number, licenceTypes: LicenceTypeDto[]) => {
    const selectedLicence = licenceTypes?.find((licence) => licence.id + '' === key + '');
    context.setFieldValue('compositionMatrixId', selectedLicence?.compositionMatrixId);
    setShowLicenceTypeEdit(false);
    //Only exception is Land Mobile ?
    if (+key === landMobileLicenceTypeId) {
      setPageInstruction('Licence type is not allowed to be changed to Land Simplex - General Channels.', MessageBarType.error);
      return;
    } else {
      if (+key !== +context.values?.licenceTypeId) setShowConfirmationModal(true);
    }
  };
  return (
    <AccordionContext.Consumer>
      {({ setExpanded }) => (
        <Form
          id="licence-basic-details"
          mode="EDIT"
          hideBack
          hideFormButtonsTop
          showFormButtonsBottom={!props.licence?.craftingProgress?.isBasicDetailsDone}
          onCancelEdit={() => setExpanded(false)}
        >
          <ConfirmDialog
            title={'Select OK to save the new selected licence type. Note: Any associated licences or records will be deleted.'}
            onCancel={() => setShowConfirmationModal(false)}
            // @ts-ignore
            onOk={() => context.validateForm().then(() => context.submitForm().finally(setShowConfirmationModal(false)))}
            visible={showConfirmationModal}
            body={''}
          />
          <Grid.Row>
            <Grid.Col sm={12} lg={3}>
              <ReadOnlyField label="Licence ID" name="id" />
            </Grid.Col>
            <Grid.Col sm={12} lg={3}>
              <ReadOnlyField label="Licence number" name="licenceNumber" />
            </Grid.Col>
            <Grid.Col sm={12} lg={3}>
              <ReadOnlyField label="Licence status" name="licenceStatus" />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={12} lg={5}>
              <TextField
                label="Licence reference"
                name="licenceReference"
                maxLength={100}
                readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone}
              />
            </Grid.Col>
          </Grid.Row>
          <ServiceLevelReadOnly
            allowChangeType={!isSatelliteType(licenceTypeId) && !props.licence?.craftingProgress?.isBasicDetailsDone}
            onLicenceTypeChange={handleLicenceTypeChange}
            showLicenceTypeApply={showLicenceTypeApply}
          />
          {showLicenceTypeEdit && (
            <ServiceLevelSelector
              serviceTypes={props.serviceTypes}
              level1={level1}
              level2={level2}
              setNextLevelToValidate={setNextLevelToValidate}
              onLevel4Change={handleLevel4Change}
              selectedLevels={selectedLevelsReadOnly}
              canEditLicenceType={true}
            />
          )}
          {setTotalRequired && (
            <Grid.Row>
              <Grid.Col sm={12} lg={2}>
                <TextField label="Number of sets" name="sets" required readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone} />
              </Grid.Col>
            </Grid.Row>
          )}
          {!isSpectrumLicenceModify && canHaveSystemId(licenceTypeId) && (
            <SystemId readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone} />
          )}
          {isRreOrSa && canBeReservedLicence(licenceTypeId) && (
            <Grid.Row style={{ marginTop: 10, marginBottom: 10 }}>
              <Grid.Col sm={12} lg={5}>
                <Checkbox label="Reserved licence" name="reserved" readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone} />
              </Grid.Col>
            </Grid.Row>
          )}
          {isSpectrumLicence && (
            <ManagementRightSection
              initialSelected={props.licence?.managementRight}
              onSelected={(item) => {
                context.setFieldValue('managementRightId', item.id);
                // This one for fixed term date validation
                context.setFieldValue('managementRight', item);
              }}
              readonly={isSpectrumLicenceModify || !!props.licence?.craftingProgress?.isBasicDetailsDone}
            />
          )}
          {!!licenceClassificationOptions?.length ? (
            <Grid.Row>
              <Grid.Col sm={12} lg={5}>
                <Dropdown
                  label="Licence classification"
                  required
                  name="classification"
                  options={licenceClassificationOptions}
                  readOnly={
                    (props?.modifyLicence && props.licence?.licenceTypeCode !== LicenceDtoLicenceTypeCodeEnum.SPL) ||
                    isSatelliteType(licenceTypeId) ||
                    !!props.licence?.craftingProgress?.isBasicDetailsDone
                  }
                />
              </Grid.Col>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Col sm={12} lg={5}>
                <ReadonlyData label="Licence classification" value={licenceClassification} />
              </Grid.Col>
            </Grid.Row>
          )}
          {isSatelliteType(licenceTypeId) && (
            <>
              <Grid.Row>
                <Grid.Col sm={12} lg={8}>
                  <ReadonlyData label="Latitude and Longitude" value={formatLatLongs(latLongs ?? [])} renderFormat={(values) => values?.join(', ')} />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={12} lg={4}>
                  <ReadonlyData label="GSO/NGSO" value={props.application?.satellite?.satelliteType} />
                </Grid.Col>
                {props.application?.satellite?.satelliteType === SatelliteDtoSatelliteTypeEnum.GSO ? (
                  <Grid.Col sm={12} lg={8}>
                    <ReadonlyData label="Orbital position" value={props.application?.satellite?.orbitalPosition} />
                  </Grid.Col>
                ) : (
                  <Grid.Col sm={12} lg={8}>
                    <ReadonlyData label="Minimum elevation" value={props.application?.satellite?.minimumElevation} />
                  </Grid.Col>
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={12} lg={5}>
                  <ReadonlyData label="ITU SNS notice ID" value={props.application?.satellite?.ituNoticeId} />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={12} lg={5}>
                  <ReadonlyData label="Beam name" value={props.application?.satellite?.beamName} />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col sm={12} lg={4}>
                  <ReadonlyData label="Commercial name of satellite" value={props.application?.satellite?.commercialName} />
                </Grid.Col>
                <Grid.Col sm={12} lg={8}>
                  <ReadonlyData label="Satellite owner or operator" value={props.application?.satellite?.owner} />
                </Grid.Col>
              </Grid.Row>
            </>
          )}
          <Grid.Row>
            <Grid.Col sm={12} lg={3}>
              <ReadonlyData label="Engineer" value={engineerName(props.engineer, 'RSM Engineering')} />
            </Grid.Col>
            <Grid.Col sm={12} lg={5}>
              <Label>&nbsp;</Label>
              {props.licence && !props.licence?.craftingProgress?.isBasicDetailsDone && (
                <AssignEngineerButton licence={props.licence} engineer={props.engineer} onEngineerUpdated={props.onEngineerUpdated} />
              )}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={12} lg={5}>
              <Checkbox
                name="fixedTerm"
                label="Fixed term"
                topMargin
                readOnly={isSpectrumLicence || !!props.licence?.craftingProgress?.isBasicDetailsDone}
              />
            </Grid.Col>
          </Grid.Row>
          {props?.modifyLicence && (
            <Grid.Row>
              <Grid.Col sm={12} lg={5}>
                <ReadonlyData label="Commencement date" value={formatDateFromISO(context.values?.commencementDate)} />
              </Grid.Col>
              {context.values.fixedTerm && (
                <>
                  <Grid.Col sm={12} lg={2}>
                    <DatePicker
                      name="expiryDate"
                      label="Expiry date"
                      minDate={new Date()}
                      isRequired
                      dateOnlyAsString
                      readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone}
                    />
                  </Grid.Col>
                </>
              )}
            </Grid.Row>
          )}
          {!props?.modifyLicence && (
            <Grid.Row>
              <Grid.Col sm={12} lg={2}>
                <DatePicker
                  name="commencementDate"
                  label="Commencement date"
                  minDate={new Date()}
                  isRequired
                  dateOnlyAsString
                  readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone}
                />
              </Grid.Col>
              {context.values.fixedTerm && (
                <>
                  <Grid.Col sm={12} lg={1}></Grid.Col>
                  <Grid.Col sm={12} lg={2}>
                    <DatePicker
                      name="expiryDate"
                      label="Expiry date"
                      minDate={new Date()}
                      isRequired
                      dateOnlyAsString
                      readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone}
                    />
                  </Grid.Col>
                </>
              )}
            </Grid.Row>
          )}
          <MoreOrLess
            moreText="More"
            lessText="Less"
            more={
              <Grid.Row>
                {props?.modifyLicence && (
                  <Grid.Col sm={12} lg={2}>
                    <ReadonlyData label="Anniversary month" value={props.licence?.anniversaryMonth} renderFormat={prettyMonth} />
                  </Grid.Col>
                )}
                {!props?.modifyLicence && (
                  <Grid.Col sm={6} lg={2}>
                    <Dropdown
                      name="anniversaryMonth"
                      label="Anniversary month"
                      options={months}
                      required
                      readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone}
                    />
                  </Grid.Col>
                )}
                <Grid.Col sm={12} lg={1}></Grid.Col>
                <Grid.Col sm={6} lg={2}>
                  <ReadOnlyField label="Annual licence fees " name="annualLicenceFee" renderFormat={formatMonetaryValue} />
                </Grid.Col>
                {props?.modifyLicence && (
                  <Grid.Col sm={6} lg={2}>
                    <ReadonlyData label="Fees paid on" value={formatDateFromISO(props.licence?.paidDate)} />
                  </Grid.Col>
                )}
                {props?.modifyLicence && (
                  <Grid.Col sm={6} lg={2}>
                    <ReadonlyData label="Paid up till" value={isNotEmpty(props.licence?.paidTillMonth) ? paidTill : ''} />
                  </Grid.Col>
                )}
              </Grid.Row>
            }
          />
          {baseCallsign && props.baseCallSignCapture && (
            <Grid.Row>
              <Grid.Col sm={12} lg={5} data-automation-id={'base-callsign(s)'}>
                <TagPicker
                  label="Base callsign(s)"
                  name="baseCallsigns"
                  onResolveSuggestions={() => []}
                  allowFreeForm
                  inputToFieldValue={(callSign) => callSign.toUpperCase()}
                  readOnly={!isPrimaryAssociation || !!props.licence?.craftingProgress?.isBasicDetailsDone}
                  required={true}
                />
              </Grid.Col>
              {isPrimaryAssociation && !!!props.licence?.craftingProgress?.isBasicDetailsDone && (
                <Grid.Col sm={12} lg={7}>
                  <Label>&nbsp;</Label>
                  <TooltipHint content="Use 'Tab' or a mouse click outside the field to add the base callsign" />
                </Grid.Col>
              )}
            </Grid.Row>
          )}
          {baseCallsign && !props.baseCallSignCapture && (
            <Grid.Row>
              <Grid.Col lg={5}>
                <TextField
                  name="baseCallsignNotes"
                  multiline
                  label="Base callsign(s)"
                  readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone}
                />
              </Grid.Col>
              {!!!props.licence?.craftingProgress?.isBasicDetailsDone && (
                <Grid.Col lg={7}>
                  <Label>&nbsp;</Label>
                  <TooltipHint content="You can enter one or more free-form text callsigns here. Multiple callsigns must be separated by a comma." />
                </Grid.Col>
              )}
            </Grid.Row>
          )}
          {mobileCallsign && props.mobileCallSignCapture && (
            <Grid.Row>
              <Grid.Col sm={12} lg={5} data-automation-id={'mobile-callsign(s)'}>
                <TagPicker
                  label="Mobile callsign(s)"
                  name="mobileCallsigns"
                  onResolveSuggestions={() => []}
                  allowFreeForm
                  inputToFieldValue={(callSign) => callSign.toUpperCase()}
                  readOnly={!isPrimaryAssociation || !!props.licence?.craftingProgress?.isBasicDetailsDone}
                  required={true}
                />
              </Grid.Col>
              {isPrimaryAssociation && !!!props.licence?.craftingProgress?.isBasicDetailsDone && (
                <Grid.Col sm={12} lg={7}>
                  <Label>&nbsp;</Label>
                  <TooltipHint content="Use 'Tab' or a mouse click outside the field to add the mobile callsign" />
                </Grid.Col>
              )}
            </Grid.Row>
          )}
          {mobileCallsign && !props.mobileCallSignCapture && (
            <Grid.Row>
              <Grid.Col lg={5}>
                <TextField
                  name="mobileCallsignNotes"
                  multiline
                  label="Mobile callsign(s)"
                  readOnly={!!props.licence?.craftingProgress?.isBasicDetailsDone}
                />
              </Grid.Col>
              {!!!props.licence?.craftingProgress?.isBasicDetailsDone && (
                <Grid.Col lg={7}>
                  <Label>&nbsp;</Label>
                  <TooltipHint content="You can enter one or more free-form text callsigns here. Multiple callsigns must be separated by a comma." />
                </Grid.Col>
              )}
            </Grid.Row>
          )}
        </Form>
      )}
    </AccordionContext.Consumer>
  );
};

const SystemId: React.FC<{ readOnly: boolean }> = (props) => {
  const { setFieldValue, values } = useFormikContext<LicenceDto>();
  const { searchSystemId } = usePublicApi();

  return (
    <Grid.Row>
      <Grid.Col sm={12} lg={5}>
        <TagPicker
          label="System ID"
          name="classificationSystem.id"
          onResolveSuggestions={async (searchText: string) => {
            const asTag = (s: BasicSearchResult) => ({ ...s, key: s.id, name: `${s.name} (${s.description})`, code: s.name });
            if (!_.isEmpty(searchText)) {
              return searchSystemId(searchText).then((searchResults) => searchResults.map(asTag).slice(0, 10));
            }
          }}
          itemLimit={1}
          inputProps={{ placeholder: 'Start typing to search for a system ID' }}
          pickerSuggestionsProps={{
            loadingText: 'Searching for system ID',
            suggestionsHeaderText: "Suggested system ID's",
            noResultsFoundText: "No system ID's found",
          }}
          onChange={(tag?: any) => setFieldValue('classificationSystem', [...(tag ?? undefined)][0])}
          fieldValueToTag={() => ({ key: values.classificationSystem?.id ?? '', name: values.classificationSystem?.code ?? '' })}
          readOnly={props.readOnly}
        />
      </Grid.Col>
      <Grid.Col sm={12} lg={1} />
      <Grid.Col sm={12} lg={2}>
        <ReadonlyData label="CSY code" value={values.classificationSystem?.code} />
      </Grid.Col>
      <Grid.Col sm={12} lg={4}>
        <ReadonlyData label="Description" value={values.classificationSystem?.description} />
      </Grid.Col>
    </Grid.Row>
  );
};
