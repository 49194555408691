import {
  EventCriteria,
  EventResults,
  FormFiveApplicationDto,
  FormFiveDto,
  FormFourApplicationDto,
  FormFourDto,
  FormSixApplicationDto,
  FormSixDto,
  FormTwoApplicationDto,
  FormTwoDto,
  MaintainManagementRightDto,
  ManagementRightApplicationDto,
  ManagementRightDto,
  MortgageCaveatDto,
  ReviewDecisionDto,
  GetDocumentDetailsRequest,
  GetDocumentDetailsResponse,
  MortgageCaveatDetailsDto,
  MultiReviewDecisionDto,
} from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { licenceApi, documentsApi } from 'common/api/api';

export const useManagementRightApi = () => {
  const { aroundApi } = useApi();

  return {
    getManagementRight: (managementRightsId: number, config?: ApiConfig): Promise<ManagementRightDto> =>
      aroundApi(licenceApi.getManagementRight(managementRightsId), config),
    getMortgagesForManagementRight: (managementRightsId: number, config?: ApiConfig): Promise<MortgageCaveatDto[]> =>
      aroundApi(licenceApi.getMortgagesForManagementRight(managementRightsId), config),
    getCaveatsForManagementRight: (managementRightsId: number, config?: ApiConfig): Promise<MortgageCaveatDto[]> =>
      aroundApi(licenceApi.getCaveatsForManagementRight(managementRightsId), config),
    createManagementRightApplication: (managementRightApplication: ManagementRightApplicationDto, config?: ApiConfig): Promise<ManagementRightDto> =>
      aroundApi(licenceApi.createManagementRightApplication(managementRightApplication), config),
    updateManagementRightApplication: (managementRight: MaintainManagementRightDto, config?: ApiConfig): Promise<ManagementRightDto> =>
      aroundApi(licenceApi.updateManagementRightApplication(managementRight), config),
    getManagementRightApplicationExtract: (managementRightsId: number, config?: ApiConfig): Promise<Blob> =>
      aroundApi(licenceApi.getManagementRightApplicationExtract(managementRightsId, { responseType: 'blob' }), config),
    assignPeerReviewer: (applicationId: number, peerReviewerId: number, config?: ApiConfig): Promise<ManagementRightDto> =>
      aroundApi(licenceApi.assignPeerReviewer(applicationId, peerReviewerId), { ...config, showLoadingSpinner: true }),
    peerReview: (applicationId: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<ManagementRightDto> =>
      aroundApi(licenceApi.peerReview(applicationId, decision), { ...config, showLoadingSpinner: true }),
    delegateCEReview: (applicationId: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<ManagementRightDto> =>
      aroundApi(licenceApi.delegateCEReview(applicationId, decision), { ...config, showLoadingSpinner: true }),
    registrarReview: (applicationId: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<ManagementRightDto> =>
      aroundApi(licenceApi.registrarReview(applicationId, decision), { ...config, showLoadingSpinner: true }),
    getFormFour: (id: number, config?: ApiConfig): Promise<FormFourDto> => aroundApi(licenceApi.getFormFour(id), config),
    createFormFour: (formFour: FormFourApplicationDto, config?: ApiConfig): Promise<FormFourDto> =>
      aroundApi(
        licenceApi.createFormFour({
          ...formFour,
          formType: 'FormFourApplicationDto',
        }),
        config,
      ),
    updateFormFour: (id: number, formFour: FormFourApplicationDto, config?: ApiConfig): Promise<FormFourDto> =>
      aroundApi(
        licenceApi.updateFormFour(id, {
          ...formFour,
          formType: 'FormFourApplicationDto',
        }),
        config,
      ),
    commencePeerReviewFormFour: (id: number, config?: ApiConfig): Promise<void> => aroundApi(licenceApi.commencePeerReviewFormFour(id), config),
    peerReviewFormFour: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.peerReviewFormFour(id, decision), config),
    delegateCEReviewFormFour: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.delegateCEReviewFormFour(id, decision), config),
    registrarReviewFormFour: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.registrarReviewFormFour(id, decision), config),
    getFormFourApplicationExtract: (id: number, config?: ApiConfig): Promise<Blob> =>
      aroundApi(licenceApi.getFormFourApplicationExtract(id, { responseType: 'blob' }), config),
    getFormFive: (id: number, config?: ApiConfig): Promise<FormFiveDto> => aroundApi(licenceApi.getFormFive(id), config),
    createFormFive: (formFive: FormFiveApplicationDto, config?: ApiConfig): Promise<FormFiveDto> =>
      aroundApi(
        licenceApi.createFormFive({
          ...formFive,
          formType: 'FormFiveApplicationDto',
        }),
        config,
      ),
    updateFormFive: (id: number, formFive: FormFiveApplicationDto, config?: ApiConfig): Promise<FormFiveDto> =>
      aroundApi(licenceApi.updateFormFive(id, { ...formFive, formType: 'FormFiveApplicationDto' }), config),
    commencePeerReviewFormFive: (id: number, config?: ApiConfig): Promise<void> => aroundApi(licenceApi.commencePeerReviewFormFive(id), config),
    peerReviewFormFive: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.peerReviewFormFive(id, decision), config),
    delegateCEReviewFormFive: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.delegateCEReviewFormFive(id, decision), config),
    registrarReviewFormFive: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.registrarReviewFormFive(id, decision), config),
    getFormFiveApplicationExtract: (id: number, config?: ApiConfig): Promise<Blob> =>
      aroundApi(licenceApi.getFormFiveApplicationExtract(id, { responseType: 'blob' }), config),
    getFormSix: (id: number, config?: ApiConfig): Promise<FormSixDto> => aroundApi(licenceApi.getFormSix(id), config),
    createFormSix: (formSix: FormSixApplicationDto, config?: ApiConfig): Promise<FormSixDto> =>
      aroundApi(licenceApi.createFormSix({ ...formSix, formType: 'FormSixApplicationDto' }), config),
    updateFormSix: (id: number, formSix: FormSixApplicationDto, config?: ApiConfig): Promise<FormSixDto> =>
      aroundApi(
        licenceApi.updateFormSix(id, {
          ...formSix,
          formType: 'FormSixApplicationDto',
        }),
        config,
      ),
    commenceReviewFormSix: (id: number, config?: ApiConfig): Promise<void> => aroundApi(licenceApi.commenceReviewFormSix(id), config),
    peerReviewFormSix: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.peerReviewFormSix(id, decision), config),
    managerReviewFormSix: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.managerReviewFormSix(id, decision), config),
    registrarReviewFormSix: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.registrarReviewFormSix(id, decision), config),
    getFormTwo: (id: number, config?: ApiConfig): Promise<FormTwoDto> => aroundApi(licenceApi.getFormTwo(id), config),
    createFormTwo: (formTwo: FormTwoApplicationDto, config?: ApiConfig): Promise<FormTwoDto> =>
      aroundApi(
        licenceApi.createFormTwo({
          ...formTwo,
          formType: 'FormTwoApplicationDto',
        }),
        config,
      ),
    updateFormTwo: (id: number, formTwo: FormTwoApplicationDto, config?: ApiConfig): Promise<FormTwoDto> =>
      aroundApi(licenceApi.updateFormTwo(id, { ...formTwo, formType: 'FormTwoApplicationDto' }), config),
    commenceReviewFormTwo: (id: number, config?: ApiConfig): Promise<void> => aroundApi(licenceApi.commenceReviewFormTwo(id), config),
    rightholderReviewFormTwo: (id: number, decision: MultiReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.rightholderReviewFormTwo(id, decision), config),
    caveatorReviewFormTwo: (id: number, decision: MultiReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.caveatorReviewFormTwo(id, decision), config),
    registrarReviewFormTwo: (id: number, decision: ReviewDecisionDto, config?: ApiConfig): Promise<void> =>
      aroundApi(licenceApi.registrarReviewFormTwo(id, decision), config),
    getManagementRightEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(licenceApi.getManagementRightEvents(entityId, criteria), config),
    getDocumentDetails: (request: GetDocumentDetailsRequest, config?: ApiConfig): Promise<GetDocumentDetailsResponse> =>
      aroundApi(documentsApi.getDocumentDetails(request), config),
    getMortgageCaveat: (mcvGroupId: number, config?: ApiConfig): Promise<MortgageCaveatDetailsDto> =>
      aroundApi(licenceApi.getMortgageCaveat(mcvGroupId), config),
    getFormOne: (id: number, config?: ApiConfig): Promise<ManagementRightDto> => aroundApi(licenceApi.getFormOne(id), config),
  };
};
