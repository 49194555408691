import * as React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import TaskRoute from 'dashboard/tasklist/TaskRoute';

import NotFoundPage from '../../common/NotFoundPage';

/**
 * The first part of the path correlates to the task form key
 * example: /craft-licence/:taskId - craft-licence is the form key defined for task Craft licence.
 * @returns
 */
const TaskRouter: React.FC = () => {
  const { path } = useRouteMatch<{ taskId: string }>();
  const licenceType = ({ licenceTypeCode }: any) => (licenceTypeCode === 'SPL' ? 'spectrum' : 'radio');

  return (
    <>
      <Switch>
        <TaskRoute path={`${path}/craft-licence/:taskId`} to={(vars) => `/ui/app/licence/${licenceType(vars)}/craft/${vars.licenceId}`} />
        <TaskRoute path={`${path}/certify-licence/:taskId`} to={(vars) => `/ui/app/licence/${licenceType(vars)}/craft/${vars.licenceId}`} />
        <TaskRoute path={`${path}/continue-satellite-application/:taskId`} to={(vars) => `/ui/app/licence/apply/${vars.applicationId}`} />
        <TaskRoute path={`${path}/modify-licence/:taskId`} to={(vars) => `/ui/app/licence/${licenceType(vars)}/craft/${vars.licenceId}`} />
        <TaskRoute path={`${path}/la-decision/:taskId`} to={(vars) => `/ui/app/licence/${licenceType(vars)}/view/${vars.licenceId}`} />
        <TaskRoute path={`${path}/la-review/:taskId`} to={(vars) => `/ui/app/licence/${licenceType(vars)}/view/${vars.licenceId}`} />
        <TaskRoute path={`${path}/lm-decision/:taskId`} to={(vars) => `/ui/app/licence/${licenceType(vars)}/view/${vars.licenceId}`} />
        <TaskRoute path={`${path}/confirm-planned-licence/:taskId`} to={(vars) => `/ui/app/licence/${licenceType(vars)}/view/${vars.licenceId}`} />
        <TaskRoute path={`${path}/grant-licence/:taskId`} to={(vars) => `/ui/app/licence/${licenceType(vars)}/view/${vars.licenceId}`} />
        {/* Licence to supply tasks */}
        <TaskRoute path={`${path}/approve-licence-to-supply/:taskId`} to={(vars) => `/ui/app/licence-to-supply/maintain/${vars.id}`} />
        <TaskRoute path={`${path}/review-client-registration/:taskId`} to={(vars) => `/ui/app/client/review/${vars.taskId}`} />
        {/* Management right form one tasks */}
        <TaskRoute path={`${path}/craft-management-right/:taskId`} to={(vars) => `/ui/app/licence/management-rights/craft/${vars.applicationId}`} />
        <TaskRoute path={`${path}/peer-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/craft/${vars.applicationId}`} />
        <TaskRoute path={`${path}/ce-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/craft/${vars.applicationId}`} />
        {/* Registrar queue */}
        <TaskRoute
          path={`${path}/registrar-review/:taskId`}
          to={(vars) =>
            `/ui/app/licence/management-rights/${vars.formName}/${vars.formName === 'craft' ? vars.applicationId : vars.formId ?? vars.applicationId}`
          }
        />
        {/* Consent subprocess */}
        <TaskRoute
          path={`${path}/consentor-review/:taskId`}
          to={(vars) => `/ui/app/licence/management-rights/${vars.formName}/${vars.formId ?? vars.applicationId}/${vars.consent.id}`}
        />
        {/* Management right form two tasks */}
        <TaskRoute path={`${path}/form-two/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-two/${vars.formId}`} />
        <TaskRoute
          path={`${path}/form-two-rightholder-review/:taskId`}
          to={(vars) => `/ui/app/licence/management-rights/form-two/${vars.formId}/${vars.rightholderConsent.id}`}
        />
        <TaskRoute
          path={`${path}/form-two-caveator-review/:taskId`}
          to={(vars) => `/ui/app/licence/management-rights/form-two/${vars.formId}/${vars.caveatorConsent.id}`}
        />
        {/* Management right form four tasks */}
        <TaskRoute path={`${path}/form-four-draft/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-four/${vars.formId}`} />
        <TaskRoute path={`${path}/form-four-peer-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-four/${vars.formId}`} />
        <TaskRoute path={`${path}/form-four-ce-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-four/${vars.formId}`} />
        {/* Management right form five tasks */}
        <TaskRoute path={`${path}/form-five-draft/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-five/${vars.formId}`} />
        <TaskRoute path={`${path}/form-five-peer-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-five/${vars.formId}`} />
        <TaskRoute
          path={`${path}/form-five-delegate-ce-review/:taskId`}
          to={(vars) => `/ui/app/licence/management-rights/form-five/${vars.formId}`}
        />
        {/* Management right form six tasks */}
        <TaskRoute path={`${path}/form-six-draft/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-six/${vars.formId}`} />
        <TaskRoute path={`${path}/form-six-peer-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-six/${vars.formId}`} />
        <TaskRoute
          path={`${path}/form-six-manager-review/:taskId`}
          to={(vars) => `/ui/app/licence/management-rights/form-six/${vars.formId}/${vars.adjacentManagerApproval.managementRightId}`}
        />
        {/* New spectrum licence form seven */}
        <TaskRoute path={`${path}/grant-spectrum-licence/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-seven/${vars.formId}`} />
        <TaskRoute path={`${path}/form-seven-peer-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-seven/${vars.formId}`} />

        <TaskRoute path={`${path}/form-eight/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-eight/${vars.formId}`} />

        {/*form nine */}
        <TaskRoute path={`${path}/form9-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-nine/${vars.formId}`} />
        <TaskRoute path={`${path}/peer-review-form9/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-nine/${vars.formId}`} />
        {/*form ten */}
        <TaskRoute path={`${path}/form10-review/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-ten/${vars.formId}`} />
        <TaskRoute path={`${path}/peer-review-form10/:taskId`} to={(vars) => `/ui/app/licence/management-rights/form-ten/${vars.formId}`} />

        {/* form 11 tasks */}
        <TaskRoute path={`${path}/mortgagor-submit/:taskId`} to={(vars) => `/ui/app/licence/mortgage-caveat/form-11/${vars.formId}`} />
        <TaskRoute path={`${path}/mortgagee-review/:taskId`} to={(vars) => `/ui/app/licence/mortgage-caveat/form-11/${vars.formId}`} />
        <TaskRoute path={`${path}/assetowner-review/:taskId`} to={(vars) => `/ui/app/licence/mortgage-caveat/form-11/${vars.formId}`} />
        {/* form 18 tasks */}
        <TaskRoute path={`${path}/submit-form18/:taskId`} to={(vars) => `/ui/app/licence/mortgage-caveat/form-18/${vars.formId}`} />
        {/* form 20 tasks */}
        <TaskRoute path={`${path}/submit-form20/:taskId`} to={(vars) => `/ui/app/licence/mortgage-caveat/form-20/${vars.formId}`} />
        <TaskRoute path={`${path}/submit-form21/:taskId`} to={(vars) => `/ui/app/licence/mortgage-caveat/form-21/${vars.formId}`} />

        {/* Investigations */}
        <TaskRoute path={`${path}/maintain-complaint/:taskId`} to={(vars) => `/ui/app/investigation/complaint/maintain/${vars.caseNumber}`} />
        <TaskRoute path={`${path}/maintain-audit/:taskId`} to={(vars) => `/ui/app/investigation/audit/maintain/${vars.caseNumber}`} />
        <TaskRoute path={`${path}/view-audit/:taskId`} to={(vars) => `/ui/app/investigation/audit/view/${vars.caseNumber}`} />
        <TaskRoute path={`${path}/maker-recommend/:taskId`} to={(vars) => `/ui/app/investigation/audit/recommendation/${vars.caseNumber}`} />
        <TaskRoute path={`${path}/manager-review/:taskId`} to={(vars) => `/ui/app/investigation/audit/review/${vars.caseNumber}`} />

        <Route path={`${path}`} component={NotFoundPage} />
      </Switch>
    </>
  );
};

export const useTaskNavigation = () => {
  const history = useHistory();

  return {
    navigateToTask: (taskName?: string, taskId?: string) => {
      history.push(`/ui/app/tasks/${taskName}/${taskId}`);
    },
  };
};

export default TaskRouter;
